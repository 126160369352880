import axios from 'axios'

const accounts = {
    namespaced: true,

    state: {
    },

    actions: {
        async getAccounts({ rootState }, { }) {
            try {
                const uri = `/v1/accounts`
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: {},
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch accounts: ${err.response.data.message}`)
                }
            }
        },

        async getAccount({ rootState }, { accountName }) {
            try {
                const uri = `/v1/account/${accountName}`
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch account info: ${err.response.data.message}`)
                }
            }
        },

        async getPnL({ rootState }, { accountName, startDate, endDate }) {
            try {
                const uri = `/v1/account/${accountName}/pnl`
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: {
                            'start_time': startDate,
                            'end_time': endDate,
                        },
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch account available balance: ${err.response.data.message}`)
                }
            }
        },

        async getAvailableBalance({ rootState }, { accountName }) {
            try {
                const uri = `/v1/account/${accountName}/available-balance`
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: {
                        },
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch account available balance: ${err.response.data.message}`)
                }
            }
        },

        async getBalances({ rootState }, { accountName, by, startDate, endDate }) {
            try {
                const uri = `/v1/account/${accountName}/balances`
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: {
                            'start_time': startDate,
                            'end_time': endDate,
                            'by': by,
                        },
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch account balance: ${err.response.data.message}`)
                }
            }
        },

        async getTotalBalances({ rootState }, { accountName, fiatCurrency, startDate, endDate }) {
            try {
                const uri = `/v1/account/${accountName}/total-balances`
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: {
                            'start_time': startDate,
                            'end_time': endDate,
                        },
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch account total balance: ${err.response.data.message}`)
                }
            }
        },

        async getTrades({ rootState }, { accountName, instrument, side, tradeId, orderId, startDate, endDate }) {
            try {
                const uri = `/v1/account/${accountName}/trades`
                const params = {
                    'start_time': startDate,
                    'end_time': endDate,
                    'instrument': (instrument && instrument.length >= 1) ? instrument : null,
                    'trade_id': (tradeId && tradeId.length >= 1) ? tradeId : null,
                    'order_id': (orderId && orderId.length >= 1) ? orderId : null,
                };
            
                if (side === 'buy' || side === 'sell') {
                    params['side'] = side;
                }

                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: params,
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch account trades: ${err.response.data.message}`)
                }
            }
        },

        async getPrices({ rootState }, { accountName, returnType, startDate, endDate }) {
            try {
                const uri = `/v1/account/${accountName}/prices`
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: {
                            'start_time': startDate,
                            'end_time': endDate,
                            'return_type': returnType,
                        },
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch account prices: ${err.response.data.message}`)
                }
            }
        },

        async syncAccount({ rootState }, { accountName }) {
            try {
                const uri = `/v1/account/${accountName}/sync`;
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        responseType: 'text',  // stream is not supported
                    }
                );
                const url = response.request.responseURL.replace(response.config.baseURL, '');
                console.log(`GET "${url}" ${response.status} (${response.statusText})`);
                return response;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to sync account: ${err.response.data.message}`)
                }
            }
        }
        
    },

    mutations: {
    }
}

export default accounts
