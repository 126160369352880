import axios from 'axios'

const aggregated = {
    namespaced: true,

    state: {
    },

    actions: {
        async getPnL({ rootState }, { startDate, endDate }) {
            try {
                const uri = `/v1/pnl`
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: {
                            'start_time': startDate,
                            'end_time': endDate,
                        },
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch aggregated pnl: ${err.response.data.message}`)
                }
            }
        },

        async getAvailableBalance({ rootState }, { }) {
            try {
                const uri = `/v1/available-balances`
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: {
                        },
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch aggregated available balance: ${err.response.data.message}`)
                }
            }
        },

        async getBalances({ rootState }, { by, startDate, endDate }) {
            try {
                const uri = `/v1/balances`
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: {
                            'start_time': startDate,
                            'end_time': endDate,
                            'by': by,
                        },
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch aggregated balances: ${err.response.data.message}`)
                }
            }
        },

        async getTotalBalances({ rootState }, { startDate, endDate }) {
            try {
                const uri = `/v1/total-balances`
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: {
                            'start_time': startDate,
                            'end_time': endDate,
                        },
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch aggregated total balance: ${err.response.data.message}`)
                }
            }
        },

        async getTrades({ rootState }, { instrument, side, tradeId, orderId, startDate, endDate }) {
            try {
                const uri = `/v1/trades`
                const params = {
                    'start_time': startDate,
                    'end_time': endDate,
                    'instrument': (instrument && instrument.length >= 1) ? instrument : null,
                    'trade_id': (tradeId && tradeId.length >= 1) ? tradeId : null,
                    'order_id': (orderId && orderId.length >= 1) ? orderId : null,
                };
            
                if (side === 'buy' || side === 'sell') {
                    params['side'] = side;
                }

                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: params,
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch aggregated trades: ${err.response.data.message}`)
                }
            }
        },

        async getPrices({ rootState }, { returnType, startDate, endDate }) {
            try {
                const uri = `/v1/prices`
                const response = await axios.get(
                    uri,
                    {
                        headers: { 'Authorization': `Bearer ${rootState.auth.sessionToken}` },
                        params: {
                            'start_time': startDate,
                            'end_time': endDate,
                            'return_type': returnType,
                        },
                    }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(`unable to fetch aggregated prices: ${err.response.data.message}`)
                }
            }
        },
    },

    mutations: {
    }
}

export default aggregated
