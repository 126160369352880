import { SET_ACTIVE_REFRESH_INTERVAL, SET_ACTIVE_TIMEFRAME, SET_LOADING_REF, 
         SET_ACTIVE_BALANCE_CHART, SET_ACTIVE_PRICE_CHART, SET_ACTIVE_TAB, SET_AVAILABLE_BALANCE_TS } from '@/store/mutation-types';

// Global defaults
export const DEFAULT_REFRESH_INTERVAL = '10'  // refresh every 10 seconds
export const DEFAULT_TIME_FRAME = '2592000'  // fetch 30 days since utcnow()
export const DEFAULT_TAB = 'Balance'
export const DEFAULT_BALANCE_CHART = 'by_currency'
export const DEFAULT_PRICE_CHART = 'relative'

// Local storage items
export const ACTIVE_TIMEFRAME_KEY = 'timeframe'
export const ACTIVE_REFRESH_INTERVAL_KEY = 'refresh_interval'
export const ACTIVE_TAB_KEY = 'tab'
export const ACTIVE_BALANCE_CHART_KEY = 'balance_chart'
export const ACTIVE_PRICE_CHART_KEY = 'price_chart'


const settings = {
    namespaced: true,

    state: {
        timeFrameOptions: [
                { label: "Last 24 hours", key: "86400" },
                { label: "Last 3 days", key: "259200" },
                { label: "Last 7 days", key: "604800" },
                { label: "Last 30 days", key: "2592000" },
                { label: "Last 90 days", key: "7776000" },
                { label: "Last 180 days", key: "15552000" },
                { label: "Last 1 year", key: "31536000" },
                { label: "Last 2 years", key: "63072000" },
                { label: "Last 5 years", key: "157680000" },
        ],
        refreshIntervals: [
                { label: "Off", key: "off" },
                { label: "5s", key: "5" },
                { label: "10s", key: "10" },
                { label: "30s", key: "30" },
                { label: "1m", key: "60" },
                { label: "5m", key: "300" },
                { label: "15m", key: "900" },
                { label: "30m", key: "1800" },
                { label: "1h", key: "3600" },
                { label: "2h", key: "7200" },
                { label: "1d", key: "86400" },
        ],
        tabOptions: [
            { label: "Balance", key: "balance" },
            { label: "PnL", key: "pnl" },
            { label: "Prices", key: "prices" },
            { label: "Account", key: "account" },
            { label: "Trades", key: "trades" },
            { label: "Transfers", key: "transfers" },
        ],
        balanceChartOptions: [
            // { label: "Total balance", key: "total_balance" },
            { label: "Per currency", key: "by_currency" },
            { label: "By volume", key: "by_volume" },
        ],
        priceChartOptions: [
            { label: "Rel. Return", key: "relative" },
            { label: "Absolute Return", key: "absolute" },
        ],
        activeRefreshInterval: localStorage.getItem(ACTIVE_REFRESH_INTERVAL_KEY) || DEFAULT_REFRESH_INTERVAL,
        activeTimeFrame: localStorage.getItem(ACTIVE_TIMEFRAME_KEY) || DEFAULT_TIME_FRAME,
        activeBalanceChart: localStorage.getItem(ACTIVE_BALANCE_CHART_KEY) || DEFAULT_BALANCE_CHART,
        activePriceChart: localStorage.getItem(ACTIVE_PRICE_CHART_KEY) || DEFAULT_PRICE_CHART,
        activeTab: localStorage.getItem(ACTIVE_TAB_KEY) || DEFAULT_TAB,
        availableBalanceTimestamp: null,
        loadingRef: null, // global loading indicator
    },

    getters: {
        getActiveTimeFrame: (state) => state.activeTimeFrame,
        getActiveTimeFrameValue: (state) => {
            for (const option of state.timeFrameOptions) {
                if (option.key == state.activeTimeFrame) {
                    return option.label
                }
            }
        },
        getActiveBalanceChart: (state) => state.activeBalanceChart,
        getActiveBalanceChartValue: (state) => {
            for (const option of state.balanceChartOptions) {
                if (option.key == state.activeBalanceChart) {
                    return option.label
                }
            }
        },
        getActiveTab: (state) => state.activeTab,
        getActivePriceChart: (state) => state.activePriceChart,
        getActivePriceChartValue: (state) => {
            for (const option of state.priceChartOptions) {
                if (option.key == state.activePriceChart) {
                    return option.label
                }
            }
        },
        getActiveRefreshInterval: (state) => state.activeRefreshInterval,
        getActiveRefreshIntervalValue: (state) => {
            for (const option of state.refreshIntervals) {
                if (option.key == state.activeRefreshInterval) {
                    return option.label
                }
            }
        },
        getAvailableBalanceTimestamp: (state) => state.availableBalanceTimestamp,
        getLoadingRef: (state) => state.loadingRef,
    },

    actions: {},

    mutations: {
        [SET_ACTIVE_TIMEFRAME](state, key) {
            state.activeTimeFrame = key;
            localStorage.setItem(ACTIVE_TIMEFRAME_KEY, key);
        },
        [SET_ACTIVE_REFRESH_INTERVAL](state, key) {
            state.activeRefreshInterval = key
            localStorage.setItem(ACTIVE_REFRESH_INTERVAL_KEY, key);
        },
        [SET_ACTIVE_TAB](state, key) {
            state.activeTab = key;
            localStorage.setItem(ACTIVE_TAB_KEY, key);
        },
        [SET_ACTIVE_BALANCE_CHART](state, key) {
            state.activeBalanceChart = key;
            localStorage.setItem(ACTIVE_BALANCE_CHART_KEY, key);
        },
        [SET_ACTIVE_PRICE_CHART](state, key) {
            state.activePriceChart = key;
            localStorage.setItem(ACTIVE_PRICE_CHART_KEY, key);
        },
        [SET_AVAILABLE_BALANCE_TS](state, dt) {
            state.availableBalanceTimestamp = dt;
        },
        [SET_LOADING_REF](state, flag) {
            state.loadingRef = flag;
        },
    },
}

export default settings
