// Mutation types for the store

// Authentication
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

// Session
export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN'

// Settings related
export const SET_ACTIVE_REFRESH_INTERVAL = 'SET_ACTIVE_REFRESH_INTERVAL'
export const SET_ACTIVE_TIMEFRAME = 'SET_ACTIVE_TIMEFRAME'
export const SET_LOADING_REF = 'SET_LOADING_REF'

// Balance chart related
export const SET_ACTIVE_BALANCE_CHART = 'SET_ACTIVE_BALANCE_CHART'
export const SET_AVAILABLE_BALANCE_TS = 'SET_AVAILABLE_BALANCE_TS'

// Price chart related
export const SET_ACTIVE_PRICE_CHART = 'SET_ACTIVE_PRICE_CHART'

// Tab related
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'