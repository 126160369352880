import { createStore } from 'vuex'
import settings from './settings';
import auth from './auth';
import account from './account';
import aggregated from './aggregated';


export default createStore({
    modules: {
        settings,
        auth,
        account,
        aggregated,
    },
    plugins: [
    ]
})
