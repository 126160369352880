<template>
      <n-data-table
        :ref="tableRef"
        size="small"
        :bordered="true"
        :single-line="isMobile ? true : false"
        :columns="columns"
        :data="data"
        :summary="summary"
        :pagination="pagination"
        :row-key="rowKey"
        :class="{ 'mobile-table': isMobile }"
      />
  </template>

<script>
import axios from 'axios'
import { defineComponent, ref, watch, onMounted, onUnmounted, computed, h } from 'vue'
import { useLoadingBar, useMessage, NFormItemGridItem, NModal, NList, NListItem, NIcon, NTag, } from 'naive-ui'
import { useStore } from 'vuex'
import { useMobile } from '@/composables/mobile'
import { useFormatting } from '@/composables/format'
import { DateTime } from 'luxon'
import { useRouter } from 'vue-router'
import { SET_AVAILABLE_BALANCE_TS } from '@/store/mutation-types'
import { SortAlphaDown, SortAlphaUpAlt, SortAmountDownAlt, SortAmountUp } from '@vicons/fa'


export default defineComponent({
    name: 'BalanceTable',
    components: {
        NFormItemGridItem, NModal, NList, NListItem, NIcon, NTag,
        SortAlphaDown, SortAlphaUpAlt, SortAmountDownAlt, SortAmountUp, 
    },
    props: {
        account: String,
    },

    setup(props) {
        const store = useStore()
        const message = useMessage()
        const { isMobile } = useMobile()
        const { formatLocalDateTime, formatQuantity, formatAmount } = useFormatting()

        // refs
        const tableRef = ref(null)
        const tableData = ref([])
        const availBalanceTimestamp = ref(null)
        const subtotalInQuote = ref({});
        const totalInEUR = ref(0);
        const checkedRows = ref([]);

        // computed properties
        const selectedAccount = computed(() => props.account ? props.account : null )
        const selectedTimeFrame = computed(() => store.getters['settings/getActiveTimeFrame'] )

        const createColumns = computed(() => {
            const columns = [
                {
                    title: 'Asset',
                    key: 'asset',
                    sorter: 'default',
                    fixed: 'left',
                    renderSorterIcon: ({ order }) => {
                        if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) });
                        if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) });
                        if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) });
                    },
                    render(row) {
                        return h(
                            NTag,
                            { 
                                type: "info", 
                                bordered: false,
                                size: !isMobile() ? 'medium' : 'small'
                            },
                            { default: () => row.base }
                        )
                    }
                },
                { title: 'Amount', key: 'volume', sorter: 'default', align: 'left',
                    renderSorterIcon: ({ order }) => {
                        if (order === false) return h(NIcon, null, { default: () => h(SortAlphaDown) })
                        if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAlphaDown) })
                        if (order === 'descend') return h(NIcon, null, { default: () => h(SortAlphaUpAlt) })
                    },
                    render(row) {
                        return h('div', [
                            h('t', null, `${formatQuantity(row.volume)}`),
                            h('t', { class: 'text-muted' }, ` ${row.base}`),
                        ]);
                    },
                },
                { title: 'Price', key: 'price', sorter: 'default', align: 'right',
                    renderSorterIcon: ({ order }) => {
                        if (order === false) return h(NIcon, null, { default: () => h(SortAlphaDown) })
                        if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAlphaDown) })
                        if (order === 'descend') return h(NIcon, null, { default: () => h(SortAlphaUpAlt) })
                    },
                    render(row) {
                        if (row.price === null) {
                            return;
                        } else if (row.is_non_eur_quote_currency) {  // other quote currency
                            return h('div', [
                                h('t', null, `${formatAmount(row.price_eur)}`),
                                h('i', { class: 'text-yellow' }, ` (${formatAmount(row.price, row.quote)})`),
                            ]);
                        } else {
                            return h('div', [
                                h('t', null, `${formatAmount(row.price_eur)}`),
                            ]);
                        }
                    },
                },
                { title: 'Total', key: 'total_eur', sorter: 'default', align: 'right',
                    renderSorterIcon: ({ order }) => {
                        if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                        if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                        if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) })
                    },
                    render(row) {
                        if (row.total_eur === null) {
                            return;
                        } else if (row.is_non_eur_quote_currency) { // other quote currency
                            return h('div', [
                                h('t', null, `${formatAmount(row.total_eur)}`),
                                h('i', { class: 'text-yellow' }, ` (${formatAmount(row.total, row.quote)})`),
                            ]);
                        } else {
                            return h('div', [
                                h('t', null, `${formatAmount(row.total_eur)}`),
                            ]);
                        }
                    },
                },
            ];

            // if (!isMobile()) {
            //     columns.unshift({
            //         title: 'Asset',
            //         key: 'asset',
            //         sorter: 'default',
            //         renderSorterIcon: ({ order }) => {
            //             if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) });
            //             if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) });
            //             if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) });
            //         },
            //         render(row) {
            //             return h(
            //             NTag,
            //             { 
            //                 type: "info", 
            //                 bordered: false,
            //             },
            //             { default: () => row.base }
            //             );
            //         },
            //     });
            // }

            return columns;
        });

        const getAvailableBalanceForAccount = async () => {
            const url = selectedAccount.value == 'all' ? 'aggregated/getAvailableBalance' : 'account/getAvailableBalance'

            try {
                const response = await store.dispatch(url, {
                    accountName: selectedAccount.value,
                })
                availBalanceTimestamp.value = DateTime.fromISO(response.time, { zone: 'utc' }).toLocal();
                store.commit(`settings/${SET_AVAILABLE_BALANCE_TS}`, availBalanceTimestamp.value)

                for (let i = 0; i < response.asset.length; i++) {
                    const row = {
                        asset: response.asset[i],
                        volume: response.volume[i],
                        price: response.price[i],
                        total: response.price[i] * response.volume[i],
                        price_eur: response.price_eur[i],
                        total_eur: response.total_eur[i],
                        ...(() => {
                            const [base, quote] = response.asset[i].split('-').map(part => part.split('_')[0]);
                            return { 
                                base, 
                                quote,
                                is_non_eur_quote_currency: quote !== 'EUR'  // bool
                            };
                        })()
                    };

                    // Add the row to the table data
                    tableData.value.push(row);

                    // Update the subtotal balance for the quote currency
                    if (!subtotalInQuote.value[row.quote]) {
                        subtotalInQuote.value[row.quote] = 0;
                    }
                    subtotalInQuote.value[row.quote] += row.total;

                    // Update the total balance in eur
                    totalInEUR.value += row.total_eur;
                }
            } catch (err) {
                message.error(String(err))
            }
        }

        const createSummary = () => {
            if (!tableData.value) { return }

            // Generate formatted summary for subtotal balance
            const subtotalBalanceSummary = Object.entries(subtotalInQuote.value).map(([quote, total]) => 
                h('li', null, `${formatAmount(total, quote)}`)
            );

            return {
                asset: {
                    value: null,
                },
                volume: {
                    value: null,
                },
                price: {
                    value: null,
                },
                total_eur: {
                    value: h('div', [
                        h('ul', {style: { listStyleType: 'none'}}, subtotalBalanceSummary),
                        h('hr'),
                        h('span', null, `Total ${formatAmount(totalInEUR.value)}`),
                    ]),
                },
            }
        }

        const clearTableData = () => {
            subtotalInQuote.value = {}
            totalInEUR.value = 0
            availBalanceTimestamp.value = null
            tableData.value = []
        }

        // watch
        watch(selectedTimeFrame, async () => {
            clearTableData()
            await getAvailableBalanceForAccount()
        })
        watch(selectedAccount, async () => {
            clearTableData()
            await getAvailableBalanceForAccount()
        })

        // lifecycle hooks
        onMounted(() => {
            getAvailableBalanceForAccount()  // calculated date range, based on selected time interval
        })

        return {
            // table related
            availBalanceTimestamp,
            tableRef,
            columns: createColumns,
            data: tableData,
            summary: createSummary,
            rowKey: (row) => row.id,
            checked: checkedRows,
            pagination: computed(() => (tableData.value.length > 100) ? { pageSize: 100 } : false), // optional pagination
            isMobile: isMobile(),
            formatLocalDateTime,
        }
    },
})
</script>

<style scoped>
:deep(.text-muted) {
   color: rgba(128, 128, 128, 0.50);
}
:deep(.text-yellow) {
    color: rgb(239, 199, 124);
}
:deep(.n-data-table-td) {
    padding: 5px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-center > .n-col {
  display: flex;
  justify-content: center;
}

.nowrap-text {
    white-space: nowrap !important;
}

.mobile-table {
  font-size: 0.9em; /* smaller font size for mobile */
  white-space: nowrap !important;
  padding: 0px;
}
</style>