<template>
    <n-data-table v-if="data"
      :ref="tableRef"
      size="small"
      :bordered="true"
      :single-line="false"
      :columns="columns"
      :data="data"
      :summary="summary"
      :pagination="pagination"
      :row-key="rowKey"
      :class="{ 'mobile-table': isMobile }"
    />
  </template>

<script>
import axios from 'axios'
import { defineComponent, ref, watch, onMounted, onUnmounted, computed, h } from 'vue'
import { useLoadingBar, useMessage, NIcon, NTag, NTooltip, } from 'naive-ui'
import { useStore } from 'vuex'
import { useMobile } from '@/composables/mobile'
import { useFormatting } from '@/composables/format'
import { DateTime } from 'luxon'
import { useRouter } from 'vue-router'
import { SortAlphaDown, SortAlphaUpAlt, SortAmountDownAlt, SortAmountUp } from '@vicons/fa'
import { ArrowDown as ArrowDownIcon, ArrowUp as ArrowUpIcon, HelpCircleSharp as HelpIcon } from '@vicons/ionicons5'


export default defineComponent({
    name: 'PnlTable',
    components: {
        NIcon, NTag, NTooltip,
        SortAlphaDown, SortAlphaUpAlt, SortAmountDownAlt, SortAmountUp, 
        ArrowDownIcon, ArrowUpIcon, HelpIcon,
    },
    props: {
        account: String,
    },

    setup(props) {
        const store = useStore()
        const message = useMessage()
        const { isMobile } = useMobile()
        const { formatLocalDateTime, formatQuantity, formatAmount, formatPercentage, formatPercentageColor } = useFormatting()

        // refs
        const tableRef = ref(null)
        const tableData = ref([])
        const subtotalRealizedPnLByQuoteCurrency = ref({});
        const totalRealizedPnLByEURCurrency = ref(0);
        const subtotalUnrealizedPnLByQuoteCurrency = ref({});
        const totalUnrealizedPnLByEURCurrency = ref(0);
        const checkedRows = ref([]);

        // computed properties
        const selectedAccount = computed(() => props.account ? props.account : null )
        const selectedTimeFrame = computed(() => store.getters['settings/getActiveTimeFrame'] )

        const createColumns = [
            {
                title: !isMobile() ? 'Instrument' : 'Instrument', 
                key: 'instrument', 
                sorter: 'default', 
                fixed: 'left',
                renderSorterIcon: ({ order }) => {
                    if (order === false || order === 'ascend') 
                        return h(NIcon, null, { default: () => h(SortAmountDownAlt) });
                    if (order === 'descend') 
                        return h(NIcon, null, { default: () => h(SortAmountUp) });
                },
                render(row) {
                    return h(
                        NTag,
                        { type: "warning", bordered: false, size: !isMobile() ? 'medium' : 'small' },
                        { default: () => row.instrument }
                    );
                },
            },
            {
                title: !isMobile() ? 'Average buy price' : 'Avg buy', 
                key: 'avg_price_buy', 
                sorter: 'default', 
                align: 'right',
                renderSorterIcon: ({ order }) => {
                    if (order === false || order === 'ascend') 
                        return h(NIcon, null, { default: () => h(SortAmountDownAlt) });
                    if (order === 'descend') 
                        return h(NIcon, null, { default: () => h(SortAmountUp) });
                },
                render(row) {
                    if (row.avg_price_buy === null) return;
                    if (row.is_non_eur_quote_currency) {
                        return h('div', [
                            h('t', null, `${formatAmount(row.avg_price_buy_eur)}`),
                            h('i', { class: 'text-yellow' }, ` (${formatAmount(row.avg_price_buy, row.quote)})`),
                        ]);
                    } else {
                        return h('div', [h('t', null, `${formatAmount(row.avg_price_buy_eur)}`)]);
                    }
                },
            },
            {
                title: !isMobile() ? 'Total buy volume' : 'Tot buy vol', 
                key: 'total_volume_buy', 
                sorter: 'default', 
                align: 'left',
                renderSorterIcon: ({ order }) => {
                    if (order === false || order === 'ascend') 
                        return h(NIcon, null, { default: () => h(SortAmountDownAlt) });
                    if (order === 'descend') 
                        return h(NIcon, null, { default: () => h(SortAmountUp) });
                },
                render(row) {
                    if (row.total_volume_buy === null) return;
                    return h('div', [
                        h('t', null, `${formatQuantity(row.total_volume_buy)}`),
                        h('t', { class: 'text-muted' }, ` ${row.base}`),
                    ]);
                },
            },
            {
                title: !isMobile() ? 'Average sell price' : 'Avg sell', 
                key: 'avg_price_sell', 
                sorter: 'default', 
                align: 'right',
                renderSorterIcon: ({ order }) => {
                    if (order === false || order === 'ascend') 
                        return h(NIcon, null, { default: () => h(SortAmountDownAlt) });
                    if (order === 'descend') 
                        return h(NIcon, null, { default: () => h(SortAmountUp) });
                },
                render(row) {
                    if (row.avg_price_sell === null) return;
                    if (row.is_non_eur_quote_currency) {
                        return h('div', [
                            h('t', null, `${formatAmount(row.avg_price_sell_eur)}`),
                            h('i', { class: 'text-yellow' }, ` (${formatAmount(row.avg_price_sell, row.quote)})`),
                        ]);
                    } else {
                        return h('div', [h('t', null, `${formatAmount(row.avg_price_sell_eur)}`)]);
                    }
                },
            },
            {
                title: !isMobile() ? 'Total sell volume' : 'Tot sell vol', 
                key: 'total_volume_sell', 
                sorter: 'default', 
                align: 'left',
                renderSorterIcon: ({ order }) => {
                    if (order === false || order === 'ascend') 
                        return h(NIcon, null, { default: () => h(SortAmountDownAlt) });
                    if (order === 'descend') 
                        return h(NIcon, null, { default: () => h(SortAmountUp) });
                },
                render(row) {
                    if (row.total_volume_sell === null) return;
                    return h('div', [
                        h('t', null, `${formatQuantity(row.total_volume_sell)}`),
                        h('t', { class: 'text-muted' }, ` ${row.base}`),
                    ]);
                },
            },
            {
                title: () => {
                    if (!isMobile()) {
                        const tooltipMessage = `
                            (Average Sell Price - Average Buy Price) × Total Sell Volume = Profit or Loss
                        `;
                        const helpIcon = h(NTooltip, { placement: 'top' }, {
                            default: () => h('span', null, tooltipMessage),
                            trigger: () => h(NIcon, { class: 'ml-2', style: 'cursor: pointer;' }, {
                                default: () => h(HelpIcon)
                            })
                        });
                        return h('div', [
                            h('span', null, 'Realized PnL'),
                            helpIcon
                        ]);
                    } else {
                        return 'PnL';
                    }
                },
                key: 'realized_pnl', 
                sorter: 'default', 
                align: 'right',
                renderSorterIcon: ({ order }) => {
                    if (order === false || order === 'ascend') 
                        return h(NIcon, null, { default: () => h(SortAmountDownAlt) });
                    if (order === 'descend') 
                        return h(NIcon, null, { default: () => h(SortAmountUp) });
                },
                render(row) {
                    if (row.realized_pnl === null) return null;
                    const pnlDisplay = h('t', null, formatAmount(row.realized_pnl_eur));
                    if (row.is_non_eur_quote_currency) {
                        return h('div', [
                            pnlDisplay,
                            h('i', { class: 'text-yellow' }, ` (${formatAmount(row.realized_pnl, row.quote)})`)
                        ]);
                    } else {
                        return h('div', [pnlDisplay]);
                    }
                },
            },
            {
                title: 'PnL %',
                key: 'realized_pnl_percentage', 
                sorter: 'default', 
                align: 'right',
                width: '6%',
                renderSorterIcon: ({ order }) => {
                    if (order === false || order === 'ascend') 
                        return h(NIcon, null, { default: () => h(SortAmountDownAlt) });
                    if (order === 'descend') 
                        return h(NIcon, null, { default: () => h(SortAmountUp) });
                },
                render(row) {
                    if (row.realized_pnl_percentage === null || row.realized_pnl_percentage === 0) return;
                    return h('div', {
                        class: 'pnl-percentage', 
                        style: formatPercentageColor(row.realized_pnl_percentage)
                    }, 
                    { default: () => formatPercentage(row.realized_pnl_percentage) });
                },
            },
            {
                title: () => {
                    if (!isMobile()) {
                        const tooltipMessage = `
                            (Current Market Price - Average Buy Price) × Current Volume = Unrealized Profit or Loss
                        `;
                        const helpIcon = h(NTooltip, { placement: 'top' }, {
                            default: () => h('span', null, tooltipMessage),
                            trigger: () => h(NIcon, { class: 'ml-2', style: 'cursor: pointer;' }, {
                                default: () => h(HelpIcon)
                            })
                        });
                        return h('div', [
                            h('span', null, 'Unrealized PnL'),
                            helpIcon
                        ]);
                    } else {
                        return 'UPnL';
                    }
                },
                key: 'unrealized_pnl', 
                sorter: 'default', 
                align: 'right',
                renderSorterIcon: ({ order }) => {
                    if (order === false || order === 'ascend') 
                        return h(NIcon, null, { default: () => h(SortAmountDownAlt) });
                    if (order === 'descend') 
                        return h(NIcon, null, { default: () => h(SortAmountUp) });
                },
                render(row) {
                    if (row.unrealized_pnl === null || row.unrealized_pnl === 0) return;
                    const unrealizedDisplay = h('t', null, formatAmount(row.unrealized_pnl_eur));
                    if (row.is_non_eur_quote_currency) {
                        return h('div', [
                            unrealizedDisplay,
                            h('i', { class: 'text-yellow' }, ` (${formatAmount(row.unrealized_pnl, row.quote)})`),
                        ]);
                    } else {
                        return h('div', [unrealizedDisplay]);
                    }
                },
            },
            {
                title: 'UPnL %',
                key: 'unrealized_pnl_percentage', 
                sorter: 'default', 
                align: 'right',
                width: '6%',
                renderSorterIcon: ({ order }) => {
                    if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) })
                },
                render(row) {
                    if (row.unrealized_pnl_percentage === null) {
                        return;
                    }
                    if (row.unrealized_pnl_percentage === 0) {
                        return;
                    }
                    return h(
                        'div', {
                            class: 'pnl-percentage', 
                            style: formatPercentageColor(row.unrealized_pnl_percentage)
                        }, 
                        { default: () => formatPercentage(row.unrealized_pnl_percentage) }
                    )
                },
            },
            {
                title: 'Fee', 
                key: 'total_fee', 
                sorter: 'default',
                align: 'right',
                renderSorterIcon: ({ order }) => {
                    if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) })
                },
                render(row) {
                    if (row.total_fee === null) {
                        return;
                    } else {
                        return formatAmount(row.total_fee);
                    }
                },
            },
            {
                title: 'Total PnL', 
                key: 'total_pnl', 
                sorter: 'default',
                align: 'right',
                renderSorterIcon: ({ order }) => {
                    if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) })
                },
                render(row) {
                    if (row.total_pnl === null) {
                        return;
                    } else {
                        return formatAmount(row.total_pnl);
                    }
                },
            },
        ];

        const getPnLForAccount = async () => {
            const now = DateTime.now()
            const start_t = DateTime.fromSeconds(now.toUnixInteger() - parseInt(selectedTimeFrame.value))
            const end_t = now.plus({ seconds: 86400 });
            const url = selectedAccount.value == 'all' ? 'aggregated/getPnL' : 'account/getPnL'

            try {
                const response = await store.dispatch(url, {
                    accountName: selectedAccount.value,
                    startDate: start_t.toUTC().toFormat('yyyy-MM-dd'), // send in utc to the server
                    endDate: end_t.toUTC().toFormat('yyyy-MM-dd'),
                })

                for (let i = 0; i < response.instrument.length; i++) {
                    const row = {
                        instrument: response.instrument[i],
                        avg_price_buy: response.avg_price_buy[i],
                        avg_price_buy_eur: response.avg_price_buy_eur[i],
                        total_volume_buy: response.total_volume_buy[i],
                        avg_price_sell: response.avg_price_sell[i],
                        avg_price_sell_eur: response.avg_price_sell_eur[i],
                        total_volume_sell: response.total_volume_sell[i],
                        total_fee: response.total_fee[i],  // costs
                        // total_rewards: response.total_rewards[i],  // earnings
                        realized_pnl: response.realized_pnl[i],
                        realized_pnl_eur: response.realized_pnl_eur[i],
                        unrealized_pnl: response.unrealized_pnl[i],
                        unrealized_pnl_eur: response.unrealized_pnl_eur[i],
                        realized_pnl_percentage: response.realized_pnl_percentage[i],
                        unrealized_pnl_percentage: response.unrealized_pnl_percentage[i],
                        total_pnl: response.total_pnl[i],
                        ...(() => {
                            const [base, quote] = response.instrument[i].split('-').map(part => part.split('_')[0]);
                            return { 
                                base, 
                                quote,
                                is_non_eur_quote_currency: quote !== 'EUR'  // bool
                            };
                        })()
                    };

                    // Add the row to the table data
                    tableData.value.push(row);

                    // Update the realized pnl for the quote currency
                    if (!subtotalRealizedPnLByQuoteCurrency.value[row.quote]) {
                        subtotalRealizedPnLByQuoteCurrency.value[row.quote] = 0;
                    }
                    subtotalRealizedPnLByQuoteCurrency.value[row.quote] += row.realized_pnl;
                    totalRealizedPnLByEURCurrency.value += row.realized_pnl_eur;

                    // Update the unrealized pnl for the quote currency
                    if (!subtotalUnrealizedPnLByQuoteCurrency.value[row.quote]) {
                        subtotalUnrealizedPnLByQuoteCurrency.value[row.quote] = 0;
                    }
                    subtotalUnrealizedPnLByQuoteCurrency.value[row.quote] += row.unrealized_pnl;
                    totalUnrealizedPnLByEURCurrency.value += row.unrealized_pnl_eur;
                }
            } catch (err) {
                message.error(String(err))
            }
        }

        const createSummary = () => {
            if (!tableData.value) { return }

            // Generate formatted summary for realized PnL by quote currency
            const realizedPnLSummary = Object.entries(subtotalRealizedPnLByQuoteCurrency.value).map(([quote, total]) => 
                h('li', null, `${formatAmount(total, quote)}`)
            );

            // Generate formatted summary for unrealized PnL by quote currency
            const unrealizedPnLSummary = Object.entries(subtotalUnrealizedPnLByQuoteCurrency.value).map(([quote, total]) => 
                h('li', null, `${formatAmount(total, quote)}`)
            );

            const displayPnLTitle = !isMobile() ? 'Total ' : ''
            const displayUPnLTitle = !isMobile() ? 'Total ' : ''

            return {
                instrument: {
                    value: null,
                },
                avg_price_buy: {
                    value: null,
                },
                total_volume_buy: {
                    value: null,
                },
                avg_price_sell: {
                    value: null,
                },
                total_volume_sell: {
                    value: null,
                },
                total_fee: {
                    value: null,
                },
                realized_pnl: {
                    value: h('div', [
                        h('ul', {style: { listStyleType: 'none'}}, realizedPnLSummary),
                        h('hr'),
                        h('span', null, `${displayPnLTitle}${formatAmount(totalRealizedPnLByEURCurrency.value)}`),
                    ]),
                },
                realized_pnl_percentage: {
                    value: null,
                },
                unrealized_pnl: {
                    value: h('div', [
                        h('ul', {style: { listStyleType: 'none'}}, unrealizedPnLSummary),
                        h('hr'),
                        h('span', null, `${displayUPnLTitle}${formatAmount(totalUnrealizedPnLByEURCurrency.value)}`),
                    ]),
                },
                unrealized_pnl_percentage: {
                    value: null,
                },
                total_pnl: {
                    value: null,
                },
            }
        }

        const clearTableData = () => {
            totalRealizedPnLByEURCurrency.value = 0;
            subtotalRealizedPnLByQuoteCurrency.value = {};
            totalUnrealizedPnLByEURCurrency.value = 0;
            subtotalUnrealizedPnLByQuoteCurrency.value = {};
            tableData.value = [];
        }

        // watch
        watch(selectedTimeFrame, async () => {
            clearTableData()
            await getPnLForAccount()
        })
        watch(selectedAccount, async () => {
            clearTableData()
            await getPnLForAccount()
        })

        // lifecycle hooks
        onMounted(() => {
            getPnLForAccount()  // calculated date range, based on selected time interval
        })

        return {
            // table related
            tableRef,
            columns: createColumns,
            data: tableData,
            summary: createSummary,
            rowKey: (row) => row.id,
            checked: checkedRows,
            ArrowDownIcon, ArrowUpIcon,
            pagination: computed(() => (tableData.value.length > 100) ? { pageSize: 100 } : false), // optional pagination
            isMobile: isMobile(),
        }
    },
})
</script>

<style scoped>
:deep(.timestamp) {
    cursor: pointer;
    text-decoration: underline;
}
:deep(.instrument) {
    cursor: pointer;
    text-decoration: underline;
}
:deep(.text-muted) {
   color: rgba(128, 128, 128, 0.50);
}
:deep(.text-yellow) {
    color: rgb(239, 199, 124);
}
:deep(.n-data-table-td) {
    padding: 5px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-center > .n-col {
  display: flex;
  justify-content: center;
}

:deep(.pnl-percentage) {
    font-weight: bold;
    text-align: center;
    /* align-items: center; */
    /* justify-content: center; */
}

.mobile-table {
  font-size: 0.9em; /* smaller font size for mobile */
  white-space: nowrap !important;
  padding: 0px;
}
</style>