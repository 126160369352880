import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

// import globally
import {
    // create naive ui
    create,
    // components
    NLayout, NLayoutContent, NLayoutSider, NLayoutHeader, NLayoutFooter,
    NMenu, NGrid, NGridItem, NFormItemGridItem, NSpace, NCard, NTag,
    NH1, NH2, NH3, NH4, NH5, NH6, 
    NIcon, NText, NButton, NButtonGroup, NSwitch, NRadioButton, NRadioGroup, NDropdown, NInput, NSelect,
    NTabs, NTab, NTabPane, NRow, NCol, NEl, 
    NSpin, NProgress, NPopover, NTooltip, NDatePicker,
    NDataTable, NStatistic, NLog,
} from 'naive-ui'

const naive = create({
    components: [
        NLayout, NLayoutContent, NLayoutSider, NLayoutHeader, NLayoutFooter,
        NMenu, NGrid, NGridItem, NFormItemGridItem, NSpace, NCard, NTag,
        NH1, NH2, NH3, NH4, NH5, NH6, 
        NIcon, NText, NButton, NButtonGroup, NSwitch, NRadioButton, NRadioGroup, NDropdown, NInput, NSelect,
        NTabs, NTab, NTabPane, NRow, NCol, NEl, 
        NSpin, NProgress, NPopover, NTooltip, NDatePicker,
        NDataTable, NStatistic, NLog,
    ]
})

axios.defaults.baseURL = process.env.VUE_APP_API_URL // prefix for all urls


createApp(App)
    .use(naive)
    .use(store)
    .use(router)
    .mount('#app')
