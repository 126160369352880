<template>
    <line-chart
    :data="chartData"
    :options="chartOptions"
    />
</template>

<script>
import { defineComponent, onMounted, onUnmounted, computed, ref, watch, h } from 'vue'
import { useStore } from 'vuex'
import { useMobile } from '@/composables/mobile'
import { DateTime, Interval } from 'luxon'
import { useMessage, useLoadingBar } from 'naive-ui'
import { Line as LineChart } from 'vue-chartjs'
import { useFormatting } from '@/composables/format'
import { useSorting } from '@/composables/sorting'


export default defineComponent({
    name: 'BalanceChart',
    components: { LineChart },
    props: {
        account: String,
    },

    setup(props) {
        const store = useStore()
        const message = useMessage()
        const loadingBar = useLoadingBar()
        const { stringToRGB } = useFormatting()
        const { sortAlphabetically } = useSorting()
        const { isMobile } = useMobile()

        // refs
        const linechartRef = ref(null);
        let chartLabels = ref([])
        let chartDataSets = ref([])

        // computed properties
        const selectedAccount = computed(() => props.account ? props.account : null )
        const selectedTimeFrame = computed(() => store.getters['settings/getActiveTimeFrame'] )
        const getActiveTimeFrameValue = computed(() => store.getters['settings/getActiveTimeFrameValue'] )
        const selectedBalanceChart = computed(() => store.getters['settings/getActiveBalanceChart'] )
        const selectedBalanceChartValue = computed(() => store.getters['settings/getActiveBalanceChartValue'] )

        const chartOptions = computed(() => {
            return {
                responsive: true,
                animation: false,
                aspectRatio: isMobile() ? 1.2 : null,
                interaction: { intersect: false, mode: 'index' },
                scales: {
                    x: {
                        grid: {
                            color: 'rgb(128, 128, 128, 0.5)', // color of the grid lines
                            z: -1, // z-index of grid layer
                        },
                        type: 'time',
                        time: {
                            unit: 'day'
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: (selectedBalanceChart.value == 'by_currency') ? 'EUR' : 'Volume',
                            align: 'end',
                        },
                        grid: {
                            color: 'rgb(128, 128, 128, 0.5)', // color of the grid lines
                            z: -1, // z-index of grid layer
                        },
                        type: 'linear',
                        position: 'left',
                        min: 0,
                        suggestedMin: 0, // displays the negative balances for margin accounts
                    }
                },
                plugins: {
                    legend: { 
                        display: !isMobile(),
                        position: 'bottom',
                        align: 'start',
                    },
                    tooltip: { position: 'nearest' },
                }
            }
        })
        const chartData = computed(() => {
            let dataSets = []

            for (const d of chartDataSets.value) {  // activates the vue-chartjs data change watcher
                dataSets.push(d)
            }
            dataSets.sort(sortAlphabetically('label'))

            return {
                labels: chartLabels.value,
                datasets: dataSets
            }
        })

        // methods
        const getBalances = async () => {
            const now = DateTime.now()
            const start_t = DateTime.fromSeconds(now.toUnixInteger() - parseInt(selectedTimeFrame.value))
            const end_t = now.plus({ seconds: 86400 });
            const url = selectedAccount.value == 'all' ? 'aggregated/getBalances' : 'account/getBalances'

            try {
                const response = await store.dispatch(url, {
                    accountName: selectedAccount.value,
                    by: (selectedBalanceChart.value == 'by_currency') ? 'currency' : 'volume',  // choose by_currency or by_volume
                    startDate: start_t.toUTC().toFormat('yyyy-MM-dd'), // send in utc to the server
                    endDate: end_t.toUTC().toFormat('yyyy-MM-dd'),
                })

                chartLabels.value = response.time.map((ts) => DateTime.fromISO(ts, {zone: 'utc'}).toLocal())  // adjust to the users local timezone

                for (const asset in response.assets) {
                    const dataSet = {
                        label: `${asset}`,
                        data: response.assets[asset],
                        tension: 0,
                        borderColor: stringToRGB(asset),
                        backgroundColor: stringToRGB(asset, true),
                        fill: false,
                        pointRadius: 0,
                        borderWidth: 2.0,
                    }
                    chartDataSets.value.push(dataSet)
                }
            } catch (err) {
                message.error(String(err))
            }
        }

        const clearChartData = () => {
            chartLabels.value = []
            chartDataSets.value = []
        }

        // watch
        watch(selectedTimeFrame, async () => {
            clearChartData()
            await getBalances()
        })
        watch(selectedBalanceChart, async () => {
            clearChartData()
            await getBalances()
        })
        watch(selectedAccount, async () => {
            clearChartData()
            await getBalances()
        })

        // lifecycle hooks
        onMounted(() => {
            getBalances()  // calculated date range, based on selected time interval
        })

        return {
            chartData,
            chartOptions,
            linechartRef,
        }
    },
})
</script>
