<template>
    <!-- Tab Panes -->
    <n-card :title="selectedAccount === 'all' ? 'All accounts' : selectedAccount" :bordered="!isMobile ? true : false" size="small" :content-style="!isMobile ? '' : 'padding: 0;'" embedded>
        <n-tabs
            :type="!isMobile ? 'line' : 'card'"
            class="card-tabs"
            :default-value="activeTab"
            pane-wrapper-style="margin: 0 -4px"
            pane-style="padding-top: 12px;  box-sizing: border-box;"
            :size="!isMobile ? 'small' : 'medium'"
            @update:value="updateTab"
            animated
        >
            <!-- Balance Tab -->
            <n-tab-pane name="Balance">
                <n-grid :cols="gridCols">
                    <n-grid-item>
                        <!-- Balance Table -->
                        <n-card :title="selectedAccount === 'all' ? 'Aggregated Available Balance' : 'Available Balance'" :bordered="false" size="small" content-style="padding: 0;" embedded>
                            <template #header-extra>
                                {{ availableBalanceTimestamp ? formatLocalDateTime(availableBalanceTimestamp).slice(11, 19) : '' }}
                            </template>
                            <balance-table :account="selectedAccount" />
                        </n-card>
                    </n-grid-item>
                    <n-grid-item>
                        <!-- Total Balance Card -->
                        <n-card :title="selectedAccount === 'all' ? 'Aggregated Total Balance' : 'Total Balance'" :bordered="false" size="small" content-style="padding: 0;" embedded>
                            <total-balance-chart :account="selectedAccount" />
                        </n-card>
                    </n-grid-item>
                    <n-grid-item>
                        <!-- Balance Card -->
                        <n-card :title="selectedAccount === 'all' ? 'Aggregated Balance' : 'Balance'" :bordered="false" size="small" content-style="padding: 0;" embedded>
                            <template #header-extra>
                                <n-radio-group v-model:value="selectedBalanceChart" name="radiobuttongroup" size="small">
                                    <n-radio-button
                                        v-for="o in balanceChartOptions"
                                        :key="o.key"
                                        :value="o.key"
                                        :label="o.label"
                                        @click="updateBalanceChart(o.key)"
                                    />
                                </n-radio-group>
                            </template>
                            <balance-chart
                                v-if="selectedBalanceChart == 'by_currency' || selectedBalanceChart == 'by_volume'"
                                :account="selectedAccount"
                            />
                        </n-card>
                    </n-grid-item>
                </n-grid>
            </n-tab-pane>

            <!-- PnL Tab -->
            <n-tab-pane name="PnL">
                <n-grid :cols="gridCols">
                    <n-grid-item :span="2">
                        <!-- PnL Card -->
                        <n-card :bordered="false" size="small" content-style="padding: 0;" embedded>
                            <pnl-table :account="selectedAccount" />
                        </n-card>
                    </n-grid-item>
                </n-grid>
            </n-tab-pane>

            <!-- Trades Tab -->
            <n-tab-pane name="Trades">
                <n-grid :cols="gridCols">
                    <n-grid-item :span="2">
                        <!-- Trades Card -->
                        <n-card :bordered="false" size="small" content-style="padding: 0;" embedded>
                            <trades-table :account="selectedAccount" />
                        </n-card>
                    </n-grid-item>
                </n-grid>
            </n-tab-pane>

            <!-- Analysis Tab -->
            <n-tab-pane name="Analysis">
                <n-grid :cols="gridCols">
                    <n-grid-item :span="2">
                        <!-- Analysis Card -->
                        <n-card :bordered="false" size="small" content-style="padding: 0;" embedded>
                        </n-card>
                    </n-grid-item>
                </n-grid>
            </n-tab-pane>

            <!-- Prices Tab -->
            <n-tab-pane name="Prices">
                <n-grid :cols="gridCols">
                    <n-grid-item :span="2">
                        <!-- Prices Card -->
                        <n-card title="Prices" :bordered="false" size="small" content-style="padding: 0;" embedded>
                            <template #header-extra>
                                <n-radio-group v-model:value="selectedPriceChart" name="radiobuttongroup" size="small">
                                    <n-radio-button
                                        v-for="o in priceChartOptions"
                                        :key="o.key"
                                        :value="o.key"
                                        :label="o.label"
                                        @click="updatePriceChart(o.key)"
                                    />
                                </n-radio-group>
                            </template>
                            <price-chart
                                v-if="selectedPriceChart == 'absolute' || selectedPriceChart == 'relative'"
                                :account="selectedAccount"
                            />
                        </n-card>
                    </n-grid-item>
                </n-grid>
            </n-tab-pane>

            <!-- Transfers Tab -->
            <n-tab-pane name="Transfers">
                <n-grid :cols="gridCols">
                    <n-grid-item :span="2">
                        <!-- Transfers Card -->
                        <n-card :bordered="false" size="small" content-style="padding: 0;" embedded>
                            <!-- <transfers-table :account="selectedAccount" /> -->
                        </n-card>
                    </n-grid-item>
                </n-grid>
            </n-tab-pane>

            <!-- Account Tab -->
            <n-tab-pane name="Account">
                <!-- Account Card -->
                <n-grid :cols="gridCols">
                <n-grid-item>
                    <n-card :title="selectedAccount != 'all' ? selectedAccount : null" :bordered="false" size="small" content-style="padding: 0;" embedded>
                        <template #header-extra>
                            <n-tooltip trigger="hover">
                                <template #trigger>
                                    <n-button @click="startSyncAccount" type="primary" size="small">
                                        <template #icon>
                                            <n-icon>
                                                <refresh-icon/>
                                            </n-icon>
                                        </template>
                                    </n-button>
                                </template>
                                <span>Sync account</span>
                            </n-tooltip>
                        </template>
                        <account-table :account="selectedAccount" />
                    </n-card>
                </n-grid-item>
                <n-grid-item>
                    <!-- Terminal Card -->
                    <n-card :title="loadingRef || syncAccountStdout ? 'Terminal' : ''" :bordered="false" size="small" content-style="padding: 0;" embedded>
                        <n-log ref="syncAccountInstRef" :loading="loadingRef" :log="syncAccountStdout" :rows="25" class="terminal" />
                    </n-card>
                </n-grid-item>
                </n-grid>
            </n-tab-pane>

        </n-tabs>
    </n-card>
</template>

<script>
import { h, ref, defineComponent, onMounted, onUnmounted, computed, watch, watchEffect, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useMobile } from '@/composables/mobile'
import { useLoadingBar, useMessage } from 'naive-ui'
import { useFormatting } from '@/composables/format'
import BalanceChart from '@/components/BalanceChart.vue'
import TotalBalanceChart from '@/components/TotalBalanceChart.vue'
import PriceChart from '@/components/PriceChart.vue'
import PnlTable from '@/components/PnLTable.vue'
import BalanceTable from '@/components/BalanceTable.vue'
import TradesTable from '@/components/TradesTable.vue'
import AccountTable from '@/components/AccountTable.vue'
import { Refresh as RefreshIcon } from "@vicons/tabler";
import { SET_ACTIVE_TAB, SET_ACTIVE_BALANCE_CHART, SET_ACTIVE_PRICE_CHART } from '@/store/mutation-types'
import axios from 'axios'


export default defineComponent({
    name: 'Portfolio',
    components: {
        TotalBalanceChart, BalanceChart, PriceChart, TradesTable, PnlTable, BalanceTable, AccountTable,
        RefreshIcon,
    },
    props: {
        account: String,
    },

    setup(props) {
        const store = useStore()
        const { isMobile } = useMobile()
        const message = useMessage()
        const loadingBar = useLoadingBar()
        const { formatLocalDateTime } = useFormatting()

        // refs
        const loadingRef = ref(null)
        const syncAccountInstRef = ref(null)
        const syncAccountStdout = ref('')

        // computed properties
        const activeTab = computed(() => store.getters['settings/getActiveTab'] )
        const selectedAccount = computed(() => props.account ? props.account : 'all' )
        const selectedBalanceChart = computed(() => store.getters['settings/getActiveBalanceChart'] )
        const selectedBalanceChartValue = computed(() => store.getters['settings/getActiveBalanceChartValue'] )
        const balanceChartOptions = ref( store.state.settings.balanceChartOptions )
        const selectedPriceChart = computed(() => store.getters['settings/getActivePriceChart'] )
        const selectedPriceChartValue = computed(() => store.getters['settings/getActivePriceChartValue'] )
        const priceChartOptions = ref( store.state.settings.priceChartOptions )
        const availableBalanceTimestamp = computed(() => store.getters['settings/getAvailableBalanceTimestamp'] )

        // compute grid layout properties based on screen size
        const gridCols = computed(() => {
            const screenWidth = window.innerWidth
            if (screenWidth <= 480) return 1 // Extra small screens
            if (screenWidth <= 768) return 1 // Small screens
            if (screenWidth <= 1024) return 1 // Medium screens
            return 2 // Large screens and above
        })

        // methods
        const startSyncAccount = async () => {
            clearSyncAccountStdout();
            try {
                loadingRef.value = true;
                const response = await store.dispatch('account/syncAccount', {
                    accountName: selectedAccount.value,
                })

                const data = response.data;
                const lines = data.split('\n');

                for (let line of lines) {
                    const processedLine = line.slice(26); // remove first 26 characters
                    syncAccountStdout.value += processedLine + '\n'; // append the line to the log
                }

                loadingRef.value = false;
            }
            catch (err) {
                loadingRef.value = false;
                message.error(String(err));
            }
        };

        const clearSyncAccountStdout = () => {
            syncAccountStdout.value = ''
        }

        // watches

        // lifecycle hooks
        onMounted(() => {
            clearSyncAccountStdout()
            watchEffect(() => {
                if (syncAccountStdout.value) {
                    nextTick(() => {
                        syncAccountInstRef.value?.scrollTo({ position: 'bottom', slient: false })
                    })
                }
            })
        })

        return {
            activeTab,
            updateTab: (key) => {
                store.commit(`settings/${SET_ACTIVE_TAB}`, key)
            },
            isMobile: isMobile(),
            formatLocalDateTime,
            // account related
            selectedAccount,
            // balance chart related
            availableBalanceTimestamp,
            selectedBalanceChart,
            selectedBalanceChartValue,
            updateBalanceChart: (key) => {
                store.commit(`settings/${SET_ACTIVE_BALANCE_CHART}`, key)
            },
            balanceChartOptions,
            // price chart related
            selectedPriceChart,
            selectedPriceChartValue,
            updatePriceChart: (key) => {
                store.commit(`settings/${SET_ACTIVE_PRICE_CHART}`, key)
            },
            priceChartOptions,
            // grid related
            gridCols,
            // sync account related
            loadingRef,
            syncAccountInstRef,
            syncAccountStdout,
            startSyncAccount,
        }
    }
})
</script>

<style scoped>
.n-grid {
    max-width: 100%; /* Ensure grid does not overflow */
}
.n-tabs {
    margin: 0;
    padding: 0;
}
.n-tab-pane {
    padding: 0;
}
.n-card {
    border-radius: 8px; /* Rounded corners for cards */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for better depth */
}
.n-card .n-card-body {
    padding: 16px;
}
.mobile-margin {
    margin-top: 4px;
    margin-bottom: 16px;
}
.terminal {
    color: #ffffff;
    overflow: auto;
}
</style>