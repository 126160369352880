import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import App from '@/App.vue'
import Account from '@/views/Account.vue'

const router = createRouter({
    title: 'Particle',
    base: __dirname,
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'App',
            meta: { title: 'Particle' },
            children: [
                {
                    path: '',  // note the empty path, will be default component within router-view
                    name: 'Account',
                    component: Account,
                    props: true,
                },
            ]
        },
        {
            path: '/account/:account/',
            name: 'Account',
            meta: { title: 'Account' },
            component: Account,
            props: true,
        },
    ]
})

router.beforeEach((to, from, next) => {
    // console.log(`router: from ${from.path} to ${to.path}`)
    document.title = to.meta.title;  // set webpage title
    if ( to.params.account ) {
        document.title += ` - ${to.params.account}`;
    }

    if (!to.matched.some((record) => record)) {
        return // path not known in available routes, do nothing
    } else {
        next(); // will redirect to the to.path
    }
});

export default router
