<template>
    <n-config-provider :theme="darkTheme">
        <n-global-style />
        <n-message-provider>
            <n-loading-bar-provider>

                <!-- if authenticated, render the main layout containing header, sidebar, content and footer -->
                <main-layout v-if="isAuthenticated"></main-layout>
                <!-- else render login -->
                <login v-if="!isAuthenticated"></login>

            </n-loading-bar-provider>
        </n-message-provider>
    </n-config-provider>
</template>

<script>
import { defineComponent, onBeforeMount, computed } from 'vue'
import { useStore } from 'vuex'
import { NConfigProvider, NGlobalStyle, NMessageProvider, NLoadingBarProvider, darkTheme } from 'naive-ui'
import Login from '@/views/Login.vue'
import MainLayout from '@/views/MainLayout.vue'

import { Chart } from 'chart.js/auto';  // lazy way, see https://vue-chartjs.org/migration-guides/#tree-shaking
import chartJsPluginAnnotation from 'chartjs-plugin-annotation';
import 'chartjs-adapter-luxon';


export default defineComponent({
    name: 'App',
    components: {
        Login, MainLayout,
        NConfigProvider, NGlobalStyle, NMessageProvider, NLoadingBarProvider,
    },

    setup() {
        const store = useStore()

        onBeforeMount(async () => {
            await store.dispatch('auth/validateToken')
        })

        const isAuthenticated = computed(() => {
            return store.getters['auth/getAuthenticated']
        })

        return {
            darkTheme,
            isAuthenticated,
        }
    }
})
</script>

<style scoped>
</style>
