export function useSorting() {

    const sortAlphabetically = (key) => {
        return (a, b) => {
            if (a[key] < b[key]) { return -1 }
            if (a[key] > b[key]) { return 1 }
            return 0;
        };
    };
    const sortFloat = (key) => {
        return (a, b) => {
            if (isNaN(a[key])) { return -1 } // sort a after b
            if (isNaN(b[key])) { return 1 } // sort b after a
            return a[key] - b[key];
        };
    };
    const sortInteger = (key) => {
        return (a, b) => {
            if (a[key] < b[key]) {
                return -1; // sort a before b
            } else if (a[key] > b[key]) {
                return 1; // sort b before a
            }
            return 0; // leave them unchanged
        };
    };
    return {
        sortAlphabetically,
        sortInteger,
        sortFloat,
    }
}
