<template>
    <n-layout>
        <n-layout-header bordered>
            <nav-header></nav-header>
        </n-layout-header>
        <n-layout has-sider>
            <n-layout-sider v-if="!isMobile" bordered>
                <nav-menu></nav-menu>
            </n-layout-sider>
            <n-layout>
                <n-layout-content :class="{'content': true, 'mobile-content': isMobile}">
                    <router-view v-slot="{ Component }">
                        <component :is="Component" />
                    </router-view>
                </n-layout-content>
            </n-layout>
        </n-layout>
        <n-layout-footer v-if="!isMobile" bordered>
            <!-- footer content -->
        </n-layout-footer>
    </n-layout>
</template>


<script>
import { h, ref, defineComponent, onMounted, onUnmounted, computed, watch } from 'vue'
import { useMobile } from '@/composables/mobile'
import NavHeader from '@/views/NavHeader.vue'
import NavMenu from '@/views/NavMenu.vue'


export default defineComponent({
    name: 'MainLayout',
    components: {
        NavHeader, NavMenu,
    },

    setup() {
        const { isMobile } = useMobile()

        // refs

        // computed properties

        // methods

        // watch

        // lifecycle hooks

        return {
            isMobile: computed(() => isMobile()),
        };
    }
})
</script>

<style scoped>
/* Base styles */
.n-layout {
    display: flex;
    flex-direction: column; /* Stack header, content, and footer vertically */
}

.n-layout-content {
    padding: 16px;
    overflow: auto;
}

.n-layout-header {
    height: 75px;
}

.n-layout-footer {
    height: 20px;
}

.n-layout-sider {
    overflow: auto;
    display: flex;
}

/* Responsive Design */
@media (max-width: 767px) {
    .n-layout-header {
        height: 57px;
    }
    .n-layout-sider {
        display: none;
    }
    .n-layout-footer {
        display: none;
    }
    .n-layout-content {
        padding: 4px; /* adjust mobile padding */
    }
}
</style>