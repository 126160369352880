<template>
    <!-- Mobile View Controls -->
    <div v-if="isMobile" class="mobile-controls">
      <n-button 
        @click="toggleSearchForm" 
        size="small" 
        icon-placement="left"
      >
        <n-icon :component="Search" />
        &nbsp; Show Search
      </n-button>

      <div
        class="total-pnl"
        :style="{color: 'rgba(99, 226, 103, 0.80)'}"
        >
        Total PnL: {{ formatAmount(totalPnLValue) }}
      </div>

    </div>

    <!-- Search Form -->
    <n-grid 
      v-if="!isMobile || showSearchForm" 
      cols="2 s:3 m:4 l:5 xl:6 2xl:7" 
      :x-gap="10" 
      responsive="screen"
    >
      <n-form-item-grid-item label="Type">
        <n-select
          v-model:value="formData.inputSide"
          placeholder="All"
          :options="sideOptions"
          size="small"
        />
      </n-form-item-grid-item>

      <n-form-item-grid-item label="Instrument" path="inputInstrument">
        <n-input
          v-model:value="formData.inputInstrument"
          placeholder="Enter Instrument"
          size="small"
          clearable
        >
          <template #prefix>
            <n-icon :component="Search" />
          </template>
        </n-input>
      </n-form-item-grid-item>

      <n-form-item-grid-item label="Trade ID" path="inputTradeID">
        <n-input
          v-model:value="formData.inputTradeID"
          placeholder="Enter Trade ID"
          size="small"
          clearable
        >
          <template #prefix>
            <n-icon :component="Search" />
          </template>
        </n-input>
      </n-form-item-grid-item>

      <n-form-item-grid-item label="Order ID" path="inputOrderID">
        <n-input
          v-model:value="formData.inputOrderID"
          placeholder="Enter Order ID"
          size="small"
          clearable
        >
          <template #prefix>
            <n-icon :component="Search" />
          </template>
        </n-input>
      </n-form-item-grid-item>
    </n-grid>

    <n-data-table v-if="!isMobile && data"
        :ref="tableRef"
        size="small"
        :bordered="true"
        :single-line="false"
        :columns="columns"
        :data="data"
        :summary="summary"
        :pagination="pagination"
        :row-key="rowKey"
    />
    <n-list v-else>
        <n-list-item v-for="(trade, index) in data" :key="index">
            <div class="transaction-item">
            <n-icon 
                class="icon" 
                :color="trade.trade_direction === 1 ? 'rgba(239, 45, 74, 1.00)' : 'rgba(99, 226, 103, 0.80)'" 
                :component="trade.trade_direction === 1 ? ArrowDownIcon : ArrowUpIcon" 
            />
            <div>
                <n-text strong>{{ trade.trade_direction == 1 ? 'Buy' : 'Sell' }}</n-text>
                <div class="transaction-date">{{ formatLocalDateTime(trade.time) }}</div>
            </div>
            <div class="transaction-details">
                <n-text strong>{{ formatQuantity(trade.volume) }} {{ trade.base }}</n-text>
                <div class="transaction-euro">{{ formatAmount(trade.total, trade.quote) }}</div>
                <div 
                    class="transaction-pnl" 
                    :style="{ color: trade.realized_pnl_eur >= 0 ? 'rgba(99, 226, 103, 0.80)' : 'rgba(239, 45, 74, 1.00)' }"
                >
                {{ trade.trade_direction == -1 ? formatAmount(trade.realized_pnl_eur) : '' }}
                </div>
            </div>
            </div>
        </n-list-item>
    </n-list>
  </template>

<script>
import { defineComponent, ref, watch, onMounted, onUnmounted, computed, h, watchEffect } from 'vue'
import { useLoadingBar, useMessage, NList, NListItem, NIcon, NTag, NTooltip, } from 'naive-ui'
import { useStore } from 'vuex'
import { useMobile } from '@/composables/mobile'
import { useFormatting } from '@/composables/format'
import { DateTime } from 'luxon'
import { Search } from '@vicons/tabler'
import { SortAlphaDown, SortAlphaUpAlt, SortAmountDownAlt, SortAmountUp } from '@vicons/fa'
import { ArrowDown as ArrowDownIcon, ArrowUp as ArrowUpIcon, HelpCircleSharp as HelpIcon } from '@vicons/ionicons5'


export default defineComponent({
    name: 'TradesTable',
    components: {
        NList, NListItem, NIcon, NTag, NTooltip,
        SortAlphaDown, SortAlphaUpAlt, SortAmountDownAlt, SortAmountUp, 
        Search, ArrowDownIcon, ArrowUpIcon, HelpIcon,
    },
    props: {
        account: String,
    },

    setup(props) {
        const store = useStore()
        const message = useMessage()
        const { isMobile } = useMobile()
        const { formatLocalDateTime, formatQuantity, formatAmount, formatPercentage, formatPercentageColor } = useFormatting()

        // refs
        const formRef = ref(null)
        const formData = ref({
            inputSide: 'all',
            inputInstrument: null,
            inputTradeID: null,
            inputOrderID: null,
        })
        const showSearchForm = ref(false);
        const tableRef = ref(null)
        const tableData = ref([])
        const subtotalRealizedPnLByQuoteCurrency = ref({});
        const totalRealizedPnLByEURCurrency = ref(0);
        const totalFee = ref(0);
        const totalTradeVolume = ref(0);
        const checkedRows = ref([]);

        // computed properties
        const selectedAccount = computed(() => props.account ? props.account : null )
        const selectedTimeFrame = computed(() => store.getters['settings/getActiveTimeFrame'] )

        const createColumns = [
            { title: 'Instrument', key: 'instrument', sorter: 'default',
                renderSorterIcon: ({ order }) => {
                    if (order === false) return h(NIcon, null, { default: () => h(SortAlphaDown) })
                    if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAlphaDown) })
                    if (order === 'descend') return h(NIcon, null, { default: () => h(SortAlphaUpAlt) })
                },
                render(row) {
                    if (row.trade_direction == 1) {
                        return h(
                            NTag,
                            { 
                                type: "success", 
                                bordered: false,
                            },
                            { default: () => row.instrument }
                        )
                    } else {
                        return h(
                            NTag,
                            { 
                                type: "error", 
                                bordered: false,
                            },
                            { default: () => row.instrument }
                        )
                    }
                },
            },
            { title: 'Side', key: 'trade_direction', sorter: 'default',
                renderSorterIcon: ({ order }) => {
                    if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) })
                },
                render(row) {
                    if (row.trade_direction == 1) {
                        return h(
                            NTag,
                            { type: "success", bordered: false },
                            { default: () => 'Buy' }
                        )
                    } else {
                        return h(
                            NTag,
                            { type: "error", bordered: false },
                            { default: () => 'Sell' }
                        )
                    }
                },
            },
            { title: 'Total', key: 'total_eur', sorter: 'default', align: 'right',
                renderSorterIcon: ({ order }) => {
                    if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) })
                },
                render(row) {
                    if (row.total_eur === null) {
                        return;
                    } else if (row.is_non_eur_quote_currency) { // other quote currency
                        return h('div', [
                            h('t', null, `${formatAmount(row.total_eur)}`),
                            h('i', { class: 'text-yellow' }, ` (${formatAmount(row.total, row.quote)})`),
                        ]);
                    } else {
                        return formatAmount(row.total_eur);
                    }
                },
            },
            { title: 'Amount', key: 'volume', sorter: 'default', align: 'right',
                renderSorterIcon: ({ order }) => {
                    if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) })
                },
                render(row) {
                    return h('div', [
                        h('t', null, `${formatQuantity(row.volume)}`),
                        h('t', { class: 'text-muted' }, ` ${row.base}`),
                    ]);
                },
            },
            { title: 'Price', key: 'price', sorter: 'default', align: 'right',
                renderSorterIcon: ({ order }) => {
                    if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) })
                },
                render(row) {
                    if (row.price === null) {
                        return;
                    } else if (row.is_non_eur_quote_currency) {  // other quote currency
                        return h('div', [
                            h('t', null, `${formatAmount(row.price_eur)}`),
                            h('i', { class: 'text-yellow' }, ` (${formatAmount(row.price, row.quote)})`),
                        ]);
                    } else {
                        return formatAmount(row.price_eur);
                    }
                },
            },
            { title: 'Fee', key: 'fee', sorter: 'default', align: 'right',
                renderSorterIcon: ({ order }) => {
                    if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) })
                },
                render(row) {
                    if (row.fee === null) {
                        return;
                    } else {
                        return formatAmount(row.fee);
                    }
                },
            },
            { 
                title: () => {
                    const tooltipMessage = `
                        (Sell Price - Average Buy Price) × Sell Volume = Profit or Loss
                    `;
                    const helpIcon = h(NTooltip, { placement: 'top' }, {
                        default: () => h('span', null, tooltipMessage),
                        trigger: () => h(NIcon, { class: 'ml-2', style: 'cursor: pointer;' }, {
                            default: () => h(HelpIcon)
                        })
                    });

                    return h('div', [
                        h('span', null, 'Realized PnL'),
                        helpIcon
                    ]);
                },
                key: 'realized_pnl', sorter: 'default', align: 'right',
                renderSorterIcon: ({ order }) => {
                    if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) })
                },
                render(row) {
                    if (row.realized_pnl_eur === 0) {
                        return;
                    } else if (row.is_non_eur_quote_currency) {  // other quote currency
                        return h('div', [
                            h('t', null, `${formatAmount(row.realized_pnl_eur)}`),
                            h('i', { class: 'text-yellow' }, ` (${formatAmount(row.realized_pnl, row.quote)})`),
                        ]);
                    } else {
                        return formatAmount(row.realized_pnl_eur);
                    }
                },
            },
            { title: 'Date & time', key: 'time', sorter: 'default', defaultSortOrder: 'descend', align: 'right',
                renderSorterIcon: ({ order }) => {
                    if (order === false) return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'ascend') return h(NIcon, null, { default: () => h(SortAmountDownAlt) })
                    if (order === 'descend') return h(NIcon, null, { default: () => h(SortAmountUp) })
                },
                render(row) {
                    return formatLocalDateTime(row.time);
                },
            },
        ];

        const getTradesForAccount = async () => {
            const now = DateTime.now()
            const start_t = DateTime.fromSeconds(now.toUnixInteger() - parseInt(selectedTimeFrame.value))
            const end_t = now.plus({ seconds: 86400 });
            const url = selectedAccount.value == 'all' ? 'aggregated/getTrades' : 'account/getTrades'

            try {
                const response = await store.dispatch(url, {
                    accountName: selectedAccount.value,
                    instrument: formData.value.inputInstrument,
                    side: formData.value.inputSide,
                    tradeId: formData.value.inputTradeID,
                    orderId: formData.value.inputOrderID,
                    startDate: start_t.toUTC().toFormat('yyyy-MM-dd'), // send in utc to the server
                    endDate: end_t.toUTC().toFormat('yyyy-MM-dd'),
                })

                for (let i = 0; i < response.time.length; i++) {
                    // const time = DateTime.fromISO(response.time[i], { zone: 'utc' }).toLocal();
                    const row = {
                        time: response.time[i],  // Convert to ISO string or use time directly as needed
                        instrument: response.instrument[i],
                        volume: response.volume[i],
                        price: response.price[i],
                        price_eur: response.price_eur[i],
                        total: response.total[i], // price * volume in traded quote currency
                        total_eur: response.total_eur[i],
                        exchange_rate: response.exchange_rate[i],
                        trade_direction: response.trade_direction[i],
                        fee: response.fee[i],
                        realized_pnl: response.realized_pnl[i],
                        realized_pnl_eur: response.realized_pnl_eur[i],
                        ...(() => {
                            const [base, quote] = response.instrument[i].split('-').map(part => part.split('_')[0]);
                            return { 
                                base, 
                                quote,
                                is_non_eur_quote_currency: quote !== 'EUR'  // bool
                            };
                        })()
                    };
                    // Add the row to the table data
                    tableData.value.push(row);

                    // Update the realized pnl for the quote currency
                    if (!subtotalRealizedPnLByQuoteCurrency.value[row.quote]) {
                        subtotalRealizedPnLByQuoteCurrency.value[row.quote] = 0;
                    }
                    subtotalRealizedPnLByQuoteCurrency.value[row.quote] += row.realized_pnl;

                    // Update the realized pnl for euros
                    totalRealizedPnLByEURCurrency.value += row.realized_pnl_eur;

                    // Update sum fee (in euros)
                    totalFee.value += row.fee;

                    // Update trade volume (in euros)
                    totalTradeVolume.value += row.total_eur;
                }
            } catch (err) {
                message.error(String(err))
            }
        }

        const createSummary = () => {
            if (!tableData.value) { return }

            // Generate formatted summary for realized PnL by quote currency
            const realizedPnLSummary = Object.entries(subtotalRealizedPnLByQuoteCurrency.value).map(([quote, total]) => 
                h('li', null, `${formatAmount(total, quote)}`)
            );

            return {
                instrument: {
                    value: null,
                },
                trade_direction: {
                    value: null,
                },
                total_eur: {
                    value: h('span', null, `Trade vol. ${formatAmount(totalTradeVolume.value)}`),
                    // value: formatAmount(totalTradeVolume.value),
                },
                volume: {
                    value: null,
                },
                price: {
                    value: null,
                },
                fee: {
                    value: h('span', null, `Fee ${formatAmount(totalFee.value)}`),
                },
                realized_pnl: {
                    value: h('div', [
                        h('ul', {style: { listStyleType: 'none'}}, realizedPnLSummary),
                        h('hr'),
                        h('span', null, `Total ${formatAmount(totalRealizedPnLByEURCurrency.value)}`),
                    ]),
                },
                time: {
                    value: null,
                }
            }
        }

        const clearTableData = () => {
            totalRealizedPnLByEURCurrency.value = 0;
            subtotalRealizedPnLByQuoteCurrency.value = {};
            totalFee.value = 0;
            totalTradeVolume.value = 0;
            tableData.value = []
        }

        // watch
        watch(selectedTimeFrame, async () => {
            clearTableData()
            await getTradesForAccount()
        })
        watch(selectedAccount, async () => {
            clearTableData()
            await getTradesForAccount()
        })
        watch(
            () => formData,
            () => {
                clearTableData();
                getTradesForAccount();
            },
            { deep: true }
        );

        // lifecycle hooks
        onMounted(() => {
            getTradesForAccount()  // calculated date range, based on selected time interval
        })

        return {
            // form related
            formRef,
            formData,
            showSearchForm,
            toggleSearchForm: () => {
                showSearchForm.value = !showSearchForm.value;
            },
            sideOptions: ["All", "Buy", "Sell"].map(
                (v) => ({
                    label: v,
                    value: v.toLowerCase()
                })
            ),
            Search, ArrowDownIcon, ArrowUpIcon,
            // table related
            tableRef,
            columns: createColumns,
            data: tableData,
            summary: createSummary,
            totalPnLValue: computed(() => totalRealizedPnLByEURCurrency.value),
            rowKey: (row) => row.id,
            checked: checkedRows,
            pagination: computed(() => (tableData.value.length > 100) ? { pageSize: 100 } : false), // optional pagination
            isMobile: isMobile(),
            formatAmount,
            formatQuantity,
            formatLocalDateTime,
            formatPercentage,
        }
    },
})
</script>

<style scoped>
:deep(.timestamp) {
    cursor: pointer;
    text-decoration: underline;
}
:deep(.instrument) {
    cursor: pointer;
    text-decoration: underline;
}
:deep(.text-muted) {
   color: rgba(128, 128, 128, 0.50);
}
:deep(.text-yellow) {
    color: rgb(239, 199, 124);
}
:deep(.n-data-table-td) {
    padding: 5px;
}

.list-item {
  border-bottom: 1px solid #ddd;
  padding: 5px 0; /* Padding for spacing */
}

.list-item-row {
  padding: 5px 0; /* Padding within each row */
}

.list-item-header {
  padding-bottom: 5px; /* Padding below header */
  margin-bottom: 5px; /* Margin below header */
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-center > .n-col {
  display: flex;
  justify-content: center;
}

.nowrap-text {
    white-space: nowrap !important;
}

.mobile-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

:deep(.total-pnl) {
  font-weight: bold;
  text-align: center;
  margin-left: 10px; /* Adjust this value for better spacing */
}

.transaction-item {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distributes items evenly */
}

.icon {
  margin-right: 12px;
  font-size: 24px;
}

.transaction-details {
  text-align: right;
  margin-left: auto; /* Pushes the details to the far right */
}

.transaction-date,
.transaction-euro {
  color: #888;
}
</style>