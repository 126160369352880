import { DateTime } from 'luxon'
import { SHA256 } from 'crypto-es/lib/sha256.js';

export function useFormatting() {

    const toRelativeDate = (s_date) => {
        return DateTime.fromISO(s_date, {zone: 'utc'}).toRelative()
    }

    const formatDateTime = (value) => {
        return value.toFormat('yyyy-MM-dd HH:mm:ss')
    }

    const formatLocalDateTime = (value) => {
        return formatDateTime(DateTime.fromISO(value, { zone: 'utc'}).toLocal())
    }

    const fmtPct = (n_pct) => {
        return (n_pct * 100).toFixed(1) + '%'
    }

    function stringToRGB(value, alpha=false) {
        // based on https://github.com/brix/crypto-js/issues/274
        const hash = SHA256(value)
        const l = hash.sigBytes;                                                                                                        
        const words = hash.words;                                                                                                       

        const result = new Uint8Array(l);                                                                                                    
        let i = 0 /*dst*/, j = 0 /*src*/;
        while(true) {
            // here i is a multiple of 4
            if (i == l) { break }
            var w = words[j++];
            result[i++] = (w & 0xff000000) >>> 24;
            if (i == l) { break }
            result[i++] = (w & 0x00ff0000) >>> 16;                                                                                            
            if (i == l) { break }
            result[i++] = (w & 0x0000ff00) >>> 8;
            if (i == l) { break }
            result[i++] = (w & 0x000000ff);                                                                                                  
        }

        if (alpha) {
            return `rgba(${result[0]}, ${result[1]}, ${result[2]}, 0.3)`        
        }
        return `rgb(${result[0]}, ${result[1]}, ${result[2]})`        
    }

    // function to determine dot color based on status
    const getStatusColor = (status) => {
        const statusColorMap = {
            green: '#4caf50', // green color
            red: '#f44336',   // red color
            grey: '#9e9e9e'   // grey color
        };
        return statusColorMap[status] || '#9e9e9e'; // Default to grey
    };

    const formatQuantity = (amount, decimals) => {
        if (amount === null || isNaN(amount)) {
            return '-';
        }
        return amount.toLocaleString('nl-NL', { 
            minimumFractionDigits: decimals, 
            maximumFractionDigits: decimals 
        });
    }

    const formatAmount = (amount, currency='EUR') => {
        if (amount === null || isNaN(amount)) {
            return '-';
        }
        return amount.toLocaleString('nl-NL', { style: 'currency', currency: currency });
    }

    const formatPercentage = (amount, decimals) => {
        if (amount === null || isNaN(amount)) {
            return '-';
        }
        return `${(amount).toLocaleString('nl-NL', { 
            minimumFractionDigits: decimals, 
            maximumFractionDigits: decimals 
        })}%`;
    };

    const formatPercentageColor = (perf_score) => {
        let color = ''
        if (perf_score === null) {
            color = 'rgba(128, 128, 128, 0.50)' // neutral gray (non-existing data)
        } else if (perf_score >= 100) {
            color = 'rgba(99, 226, 103, 0.80)' // super green (superpositive)
        } else if (perf_score < 100 && perf_score >= 0) {
            color = 'rgba(91, 221, 176, 0.25)' // green
        } else if (perf_score < 0  && perf_score > -100) {
            color = 'rgba(226, 63, 66, 0.75)' // red
        } else if (perf_score <= -100) {
            color = 'rgba(239, 45, 74, 1.00)' // super red (supernegative)
        } else {
            color = 'rgba(128, 128, 128, 0.50)' // neutral gray
        }
        return { 'background-color': color }
    }

    return {
        stringToRGB, toRelativeDate, formatDateTime, formatLocalDateTime, fmtPct, getStatusColor,
        formatQuantity, formatAmount, formatPercentage, formatPercentageColor,
    }
}
