<template>
      <n-data-table v-if="!isMobile && data"
        :ref="tableRef"
        size="small"
        :bordered="true"
        :single-line="isMobile ? true : false"
        :columns="columns"
        :data="data"
      />
    <n-list v-else>
        <n-list-item v-for="(account, index) in data" :key="index">
        <n-card
            size="small"
            :bordered="false"
            :hoverable="true"
            :style="{ marginBottom: '12px' }"
        >
            <n-space vertical>
                <n-space justify="space-between">
                    <n-text strong>Created At:</n-text>
                    <n-text>{{ formatLocalDateTime(account.created_at) }}</n-text>
                </n-space>
                <n-space justify="space-between">
                    <n-text strong>Last Balance:</n-text>
                    <n-text>{{ formatLocalDateTime(account.last_balance_ts) }}</n-text>
                </n-space>
                <n-space justify="space-between">
                    <n-text strong>Last Trade:</n-text>
                    <n-text>{{ formatLocalDateTime(account.last_trade_ts) }}</n-text>
                </n-space>
                <n-space justify="space-between">
                    <n-text strong>Last Transfer:</n-text>
                    <n-text>{{ formatLocalDateTime(account.last_transfer_ts) }}</n-text>
                </n-space>
            </n-space>
        </n-card>
        </n-list-item>
    </n-list>
  </template>

<script>
import { defineComponent, ref, watch, onMounted, onUnmounted, computed, h } from 'vue'
import { useLoadingBar, useMessage, NFormItemGridItem, NModal, NList, NListItem, NIcon, NTag, } from 'naive-ui'
import { useStore } from 'vuex'
import { useMobile } from '@/composables/mobile'
import { useFormatting } from '@/composables/format'
import { SortAlphaDown, SortAlphaUpAlt, SortAmountDownAlt, SortAmountUp } from '@vicons/fa'


export default defineComponent({
    name: 'AccountTable',
    components: {
        NFormItemGridItem, NModal, NList, NListItem, NIcon, NTag,
        SortAlphaDown, SortAlphaUpAlt, SortAmountDownAlt, SortAmountUp, 
    },
    props: {
        account: String,
    },

    setup(props) {
        const store = useStore()
        const message = useMessage()
        const { isMobile } = useMobile()
        const { formatLocalDateTime } = useFormatting()

        // refs
        const tableRef = ref(null)
        const tableData = ref([])
        const loadingRef = ref(null)

        // computed properties
        const selectedAccount = computed(() => props.account ? props.account : null )
        const selectedTimeFrame = computed(() => store.getters['settings/getActiveTimeFrame'] )

        const createColumns = computed(() => {
            const columns = [
                {
                    title: 'Name',
                    key: 'name',
                    render(row) {
                        if (!isMobile()) {
                            return h(
                                NTag,
                                { 
                                    type: "info", 
                                    bordered: false,
                                },
                                { default: () => row.name }
                            )
                        } else {
                            return row.name;
                        }
                    }
                },
                { 
                    title: 'Created', 
                    key: 'created_at',
                    render(row) {
                        return row.created_at ? formatLocalDateTime(row.created_at) : null;
                    },
                },
                { 
                    title: 'Last Balance', 
                    key: 'last_balance_ts',
                    render(row) {
                        return row.last_balance_ts ? formatLocalDateTime(row.last_balance_ts) : null;
                    },
                },
                { 
                    title: 'Last Trade', 
                    key: 'last_trade_ts',
                    render(row) {
                        return row.last_trade_ts ? formatLocalDateTime(row.last_trade_ts) : null;
                    },
                },
                { 
                    title: 'Last Transfer', 
                    key: 'last_transfer_ts',
                    render(row) {
                        return row.last_transfer_ts ? formatLocalDateTime(row.last_transfer_ts) : null;
                    },
                },
            ];
            return columns;
        });

        const getAccountInfo = async () => {
            const url = selectedAccount.value == 'all' ? 'account/getAccounts' : 'account/getAccount'

            try {
                const response = await store.dispatch(url, {
                    accountName: selectedAccount.value,
                })

                if (response.accounts) {
                    tableData.value = response.accounts;
                } else {
                    const row = {
                        name: response.name,
                        created_at: response.created_at,
                        disabled_at: response.disabled_at,
                        exchange: response.exchange,
                        last_balance_ts: response.last_balance_ts,
                        last_transfer_ts: response.last_transfer_ts,
                        last_trade_ts: response.last_trade_ts,
                    };

                    // Add the row to the table data
                    tableData.value.push(row);
                }
            } catch (err) {
                message.error(String(err))
            } finally {
                loadingRef.value = false;
            }
        }

        const clearTableData = () => {
            tableData.value = []
        }

        // watch
        watch(selectedTimeFrame, async () => {
            clearTableData()
            await getAccountInfo()
        })
        watch(selectedAccount, async () => {
            clearTableData()
            await getAccountInfo()
        })

        // lifecycle hooks
        onMounted(() => {
            getAccountInfo()  // calculated date range, based on selected time interval
        })

        return {
            loadingRef,
            // table related
            tableRef,
            columns: createColumns,
            data: tableData,
            isMobile: isMobile(),
            formatLocalDateTime,
        }
    },
})
</script>

<style scoped>
:deep(.n-data-table-td) {
    padding: 5px;
}
</style>