import axios from 'axios'
import { AUTH_LOGIN, AUTH_LOGOUT, SET_SESSION_TOKEN } from '@/store/mutation-types'

// global naming
const SESSION_TOKEN = 'session_token'


const auth = {
    namespaced: true,

    state: {
        username: null, // name of currently logged in user
        sessionToken: localStorage.getItem(SESSION_TOKEN),
        isAuthenticated: !!localStorage.getItem(SESSION_TOKEN),
    },

    getters: {
        getSessionToken: (state) => state.sessionToken,
        getAuthenticated: (state) => state.isAuthenticated,
    },

    actions: {
        async validateToken({ commit, getters }, payload) {  // used as initialisation step
            // perform session validity check
            const token = getters.getSessionToken
            if (!token) {
                commit(AUTH_LOGOUT)
            }

            try {
                const uri = `/v1/user/auth/validate/`
                const response = await axios.get(
                    uri,
                    { headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    } }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`GET "${url}" ${response.status} (${response.statusText})`)

                const username = response.data.username
                commit(AUTH_LOGIN, { username, token })
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else if (err.response.status == 401) {
                    commit(AUTH_LOGOUT)
                } else {
                    throw new Error(err.response.data.message)
                }
            }
        },
        async authLogin({ commit }, payload) {
            const username = payload.username

            // perform POST to /user/login/
            try {
                const uri = `/v1/user/login/`
                const response = await axios.post(
                    uri,
                    { username: username, password: payload.password },
                    { headers: { 'Accept': 'application/json' } }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`POST "${url}" ${response.status} (${response.statusText})`)

                const token = response.data.token
                commit(AUTH_LOGIN, { username, token })
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else {
                    throw new Error(err.response.data.message)
                }
            }
        },
        async authLogout({ commit, getters }, payload) {
            const token = getters.getSessionToken
            if (!token) {
                commit(AUTH_LOGOUT)
            }

            // perform POST to /user/logout/
            try {
                const uri = `/v1/user/logout/`
                const response = await axios.post(
                    uri,
                    { token: token },
                    { headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    } }
                )
                const url = response.request.responseURL.replace(response.config.baseURL, '')
                console.log(`POST "${url}" ${response.status} (${response.statusText})`)
                // commit(AUTH_LOGOUT)
                return response.data;
            }
            catch (err) {
                if (!err.response) { // network error
                    throw new Error(`unable to connect to backend`)
                } else if (err.response.status == 401) {
                    commit(AUTH_LOGOUT)
                } else {
                    throw new Error(err.response.data.message)
                }
            }
        },
    },

    mutations: {
        [AUTH_LOGIN](state, payload) {
            // console.log(`auth: setting username '${payload.username}' and session token: '${payload.token}'`)
            state.username = payload.username;
            state.sessionToken = payload.token;
            state.isAuthenticated = true;
            localStorage.setItem(SESSION_TOKEN, payload.token);
        },
        [AUTH_LOGOUT](state) {
            // console.log(`auth: removing username and session token`)
            state.username = null;
            state.sessionToken = null;
            state.isAuthenticated = false;
            localStorage.removeItem(SESSION_TOKEN);
        },
        [SET_SESSION_TOKEN](state, token) {  // session token obtained after successful login
            localStorage.setItem(SESSION_TOKEN, token);
        },
    }
}

export default auth
